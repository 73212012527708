<!--
 * @Author: 钟澳鹏 1269372093@qq.com
 * @Date: 2023-05-17 12:08:30
 * @LastEditors: 钟澳鹏 1269372093@qq.com
 * @LastEditTime: 2023-09-15 08:55:25
 * @FilePath: \dalian\src\components\TabContent2.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="tab-content2">
    <img src="../assets/banner4.png" class="banner">
    <div class="content">
      <GridTitle text="企业简介"></GridTitle>
      <div class="grid-1">
        <img src="../assets/tab4/1.png">
        <div>深圳市中天普瑞科技有限公司成立于2010年，注册资金1000万元人民币。公司专注于信息技术、计算机软硬件开发、销售及咨询，以及仪器仪表、电子产品、机械产品、通信产品、环保设备等多领域的销售。初期专注数字化软件技术服务，逐渐发展成为科技创新领域的佼佼者。</div>
        <div>公司在人工智能领域获得多项专利，提供全方位技术解决方案，成功实施大型云计算项目，为客户提供安全、稳定的数据存储和计算服务。销售方面，公司以定制化软件开发服务为主，同时销售电子产品、通讯产品、环保设备等，回款方式灵活，包括现货结算、预付货款、延期付款等。</div>
        <div>公司以科技创新为核心，已为全国上百家企业提供定制化软件服务，赢得业界信任。在未来，公司将继续深耕现有领域，拓展新的业务领域，持续投入研发，助力客户实现数字化转型，共创美好未来。</div>
      </div>
    </div>
  </div>
</template>

<script>
import GridTitle from './GridTitle.vue'
export default {
  name: 'TabContent4',
  components:{
    GridTitle
  }
}
</script>

<style scoped>
.banner{
  height: 651px;
  width: 100%;
 }
 .content{
  width: 1200px;
  margin: 72px auto 0 auto;
 }

 .grid-1{
    font-size: 22px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    color: #666666;
    line-height: 32px;
    padding: 67px 0 57px 0;
    text-align: justify;
 }
 .grid-1>div{
    padding: 19px 0;
 }
 .grid-1>img{
    height: 555px;
    width: 1015px;
    margin: 0 auto 40px auto;
    display: block;
 }
</style>
